import React from 'react';
import "../Layouts/test.css";
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';


class Test extends React.Component {


  render() {
    return (
      <div>

<header className="page-header">
          <Navbar bg="light" variant="light">
            <Navbar.Brand href="#home">Yogipati Digital Marketing & Solutions</Navbar.Brand>
            <Nav className="mr-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/about">About Us</Nav.Link>
              <Nav.Link href="/contact">Contact us</Nav.Link>
              <Nav.Link href="/login">Login</Nav.Link>

            </Nav>
            <Form inline>
              <FormControl type="text" placeholder="Search" className="mr-sm-2" />
              <Button variant="outline-info">Search</Button>
            </Form>
          </Navbar>
        </header>


        <section id="details" class="details">
          <div class="container">

            <div class="row content">
              <div class="col-md-4 aos-init aos-animate" data-aos="fade-right">
                <img src="assets/img/details-1.png" class="img-fluid" alt="" />
              </div>
              <div class="col-md-8 pt-4 aos-init aos-animate" data-aos="fade-up">
                <h3>Refund Policy </h3>
                <p class="fst-italic">
                  We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.
                </p>
                <ul>
                  <li><i class="bi bi-check"></i>  Merchant business' return and cancellation policy must be made available to customers on website and they have to agree to its terms during the order process. Customers have to click on an "Accept" or "Agree" for return and cancellation button before submitting their payment information. Then, once the order has been submitted, policy can be sent to customers with the order confirmation email.</li>
                  <li><i class="bi bi-check"></i>  When you buy our products/services, your purchase is covered by our 30-day money-back guarantee. If you are, for any reason, not entirely happy with your purchase, we will cheerfully issue a full refund.
                    We develop and sell software that we use ourselves every day and have thousands of satisfied customers worldwide, and our support is second to none. That is why we can afford to back our products with this special guarantee. To request a refund, simply contact us with your purchase details within ninety (90) days of your purchase. Please include your order number (sent to you via email after ordering) and optionally tell us why you’re requesting a refund – we take customer feedback very seriously and use it to constantly improve our products and quality of service.
                    Refunds are not being provided for services delivered in full such as installation service and provided knowledge base hosting service. Refunds are being processed within 21 days period.</li>

                </ul>

              </div>
            </div>






          </div>
        </section>
        <footer id="footer">
    <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-lg-4 col-md-6 footer-info">
            <h3>YOGIPATI DIGITAL PRIVATE LIMITED</h3>
            <p>YogiPati Digital is a premier creative digital marketing company providing top of the line digital experiences using creative and innovative solutions of any complexity to a globally diverse clientele.</p>
          </div>

          <div class="col-lg-2 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><a href="/Disclaimer">Disclaimer Policies</a></li>
              <li><a href="/refund">Security Policies</a></li>
              <li><a href="/Efund">Refund Policies</a></li>
              <li><a href="/terms">Terms and conditions</a></li>
              <li><a href="/privacy">Privacy policy</a></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-contact">
            <h4>Contact Us</h4>
            <p>
              Maharashtra <br></br>
              
              <strong>Phone:</strong> +1 5589 55488 55<br></br>
              <strong>Email:</strong> Yogipatidigital@gmail.com<br></br>
            </p>

            <div class="social-links">
              <a href="#" class="twitter"><i class="bi bi-twitter"></i></a>
              <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>
              <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
              <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
              <a href="#" class="linkedin"><i class="bi bi-linkedin"></i></a>
            </div>

          </div>

          <div class="col-lg-3 col-md-6 footer-newsletter">
            <h4>Our Newsletter</h4>
            <p>Maharashtra.</p>
            <form action="" method="post">
              <input type="email" name="email" /><input type="submit" value="Subscribe" />
            </form>
          </div>

        </div>
      </div>
    </div>

    <div class="container">
      <div class="copyright">
        &copy; Copyright <strong>Yogipati Digital Private Limited</strong>. All Rights Reserved
      </div>
      <div class="credits">

        Designed by <a href="">Yogipati Digital</a>
      </div>
    </div>
  </footer>







      </div>
    )
  }
}

export default Test;