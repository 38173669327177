import React from 'react';
import "../Layouts/test.css";
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Carousel from 'react-bootstrap/Carousel';
import FormControl from 'react-bootstrap/FormControl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Aboutus from "../images/aboutus.jpg";

import Conc from './Conc';


import {
  BrowserRouter as Router,
  Route, Link, Switch
} from "react-router-dom";

class About extends React.Component {
  constructor() {
    super();
  }
  render() {
    return (
      <div>
        <header className="page-header">
          <Navbar bg="light" variant="light">
            <Navbar.Brand href="#home">Yogipati Digital Marketing & Solutions</Navbar.Brand>
            <Nav className="mr-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/about">About Us</Nav.Link>
              <Nav.Link href="/contact">Contact us</Nav.Link>
              <Nav.Link href="/login">Login</Nav.Link>

            </Nav>
            <Form inline>
              <FormControl type="text" placeholder="Search" className="mr-sm-2" />
              <Button variant="outline-info">Search</Button>
            </Form>
          </Navbar>
        </header>



        <section id="about">
      <div class="container" data-aos="fade-up">

        <header class="section-header">
          <h3>About Us</h3>
          <p>YogiPati Digital is a premier creative digital marketing company providing top of the line digital experiences using creative and innovative solutions of any complexity to a globally diverse clientele.</p>
        </header>

        <div class="row about-container">

          <div class="col-lg-6 content order-lg-1 order-2">
            <p>
            Our goal is simple to provide online visibility, increasing reach & engagement with your target audience, and eventually driving sales and build brand reputation.
            </p>

            <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
              <div class="icon"><i class="bi bi-card-checklist"></i></div>
              <h4 class="title"><a href="">Online Advertisement Life Cycle</a></h4>
              <p class="description">Proper Following of Advertisement Life Cycle While Targeting an Clientele</p>
            </div>

            <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
              <div class="icon"><i class="bi bi-brightness-high"></i></div>
              <h4 class="title"><a href="">RESEARCH</a></h4>
              <p class="description">Finding out the market behavior, knowing the competitors</p>
            </div>

            <div class="icon-box" data-aos="fade-up" data-aos-delay="300">
              <div class="icon"><i class="bi bi-calendar4-week"></i></div>
              <h4 class="title"><a href="">MEDIA SELECTION</a></h4>
              <p class="description">Appropriate media is selected to reach the target audience</p>
            </div>

          </div>

          <div class="col-lg-6 background order-lg-2" data-aos="zoom-in">
            <img src="assets/img/about-img.svg" class="img-fluid" alt="" />
          </div>
        </div>

        <div class="row about-extra">
          <div class="col-lg-6" data-aos="fade-right">
            <img src="assets/img/about-extra-1.svg" class="img-fluid" alt="" />
          </div>
          <div class="col-lg-6 pt-5 pt-lg-0" data-aos="fade-left">
            <h4>We plan customized campaigns to meet your organizational requirements and campaign goals</h4>
            <p>
            Our social media quality AUDIT reports and consumer research analytics with BigData & Social media metrics give you in-depth insights that will help us plan, design, implement & monitor your digital marketing and social media brand campaigns to reach relevant target audiences for measurable goals.
            </p>
            <p>
            We also provide custom designed Digital marketing and social media corporate training by the Best Digital Marketing Professional in India services for Top Organizations and Elite Academic institutions with extended digital skill up-gradation and support through the year.
            </p>
          </div>
        </div>

        <div class="row about-extra">
          <div class="col-lg-6 order-1 order-lg-2" data-aos="fade-left">
            <img src="assets/img/about-extra-2.svg" class="img-fluid" alt="" />
          </div>

          <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-right">
            <h4>Team Yogipati Digital believe to bring innovative Web applications and IT solutions to the target market. </h4>
            <p>
            Yogipati Digital is India based Turn key web and mobile, social applications solutions provider and premier Internet solutions company providing top of the line Innovative Website Designing/Marketing and SEO Services of any complexity to a globally diverse clientele.
            </p>
            <p>
            To assist our clients, in presenting a professional front to the Digital marketplace. We add value to our client’s business through our Innovative techniques to empower and make them efficient and responsive.
            </p>
            <p>
             Vision : To be the most preferred and respected brand by its customers and competitors.
            </p>
          </div>

        </div>

      </div>
    </section>














    <footer id="footer">
    <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-lg-4 col-md-6 footer-info">
            <h3>YOGIPATI DIGITAL PRIVATE LIMITED</h3>
            <p>YogiPati Digital is a premier creative digital marketing company providing top of the line digital experiences using creative and innovative solutions of any complexity to a globally diverse clientele.</p>
          </div>

          <div class="col-lg-2 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><a href="/Disclaimer">Disclaimer Policies</a></li>
              <li><a href="/refund">Security Policies</a></li>
              <li><a href="/Efund">Refund Policies</a></li>
              <li><a href="/terms">Terms and conditions</a></li>
              <li><a href="/privacy">Privacy policy</a></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-contact">
            <h4>Contact Us</h4>
            <p>
              Maharashtra <br></br>
              
              <strong>Phone:</strong> +1 5589 55488 55<br></br>
              <strong>Email:</strong> Yogipatidigital@gmail.com<br></br>
            </p>

            <div class="social-links">
              <a href="#" class="twitter"><i class="bi bi-twitter"></i></a>
              <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>
              <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
              <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
              <a href="#" class="linkedin"><i class="bi bi-linkedin"></i></a>
            </div>

          </div>

          <div class="col-lg-3 col-md-6 footer-newsletter">
            <h4>Our Newsletter</h4>
            <p>Maharashtra.</p>
            <form action="" method="post">
              <input type="email" name="email" /><input type="submit" value="Subscribe" />
            </form>
          </div>

        </div>
      </div>
    </div>

    <div class="container">
      <div class="copyright">
        &copy; Copyright <strong>Yogipati Digital Private Limited</strong>. All Rights Reserved
      </div>
      <div class="credits">

        Designed by <a href="">Yogipati Digital</a>
      </div>
    </div>
  </footer>









      </div>
    )
  }
}

export default About;